<template>
  <div>
    <v-app-bar
      height="80"
      color="cassiopee-grey lighten-1"
      app
      flat
    >
      <img 
        src="@/assets/logo-datakairos.svg" 
        alt="logo"
        height="28.6px"
        width="311.9px"
        class="form-header__logo"
      >
      <v-spacer />
      <div>
        <v-icon>$dashboard</v-icon>
        <router-link
          to="/dashboard"
          class="text-h3 text-uppercase cassiopee-purple--text mx-3 pr-9"
        >
          Tableau de bord
        </router-link>
      </div>
      <div>
        <v-icon
          color="cassiopee-purple"
          size="18"
        >
          $disconnection
        </v-icon>
        <a
          x-small
          icon="$disconnection"
          class="text-h3 text-uppercase cassiopee-purple--text mx-3 pr-9"
          @click="logout"
        >
          Deconnexion
        </a>
      </div>
      <base-modal
        width="745"
        height="580"
        class="mr-10"
      >
        <template #activator="{ on, attrs }">
          <base-button
            text
            color="cassiopee-purple"
            v-bind="attrs"
            v-on="on"
          >
            CONTACT
          </base-button>
        </template>
        <template #content>
          <v-card 
            flat
            width="435"
            class="mx-auto my-16"
          >
            <v-card-title class="text-h1 cassiopee-purple--text pl-0">
              Contacts
            </v-card-title>
            <v-divider class="cassiopee-purple" />
            <div class="mt-5 pl-0 text-caption cassiopee-purple--text">
              <ul class="pl-3">
                <li class="mb-4">
                  HUB Datacenter - concepteur Outil <br>
                  Maxime LENOIR, 06 30 87 64 42 <br>
                  maxime.lenoir@hub-dc.com 
                </li>
                <li>
                  UNIF (Université Numérique Île-de-France) : <br>
                  Florence FERRET, cheffe de Projet 06 09 58 04 49 florence.ferret@unif.fr
                </li>
              </ul>
            </div>
          </v-card>
        </template>
      </base-modal>
      <base-modal
        width="745"
        height="600"
        class="mr-8"
      >
        <template #activator="{ on, attrs }">
          <base-button-icon
            icon="$help"
            size="20"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <template #content>
          <v-card 
            flat
            width="414"
            class="mx-auto"
          >
            <v-card-title class="text-h1 cassiopee-purple--text pl-0">
              Aide - Guide de remplissage
            </v-card-title>
            <v-divider class="cassiopee-purple" />
            <p class="text-caption cassiopee-purple--text mt-5">
              Les données à compléter sont divisées en 4 étapes : profil, ressources, besoins et stratégie. La 4ème étape est facultative.
            </p>
            <div class="my-5 pl-0 text-caption cassiopee-purple--text">
              <ul class="pl-3">
                <li class="mb-2">
                  Ne pas hésiter à passer à la question suivante.
                </li>
                <li class="mb-2">
                  La sauvegarde est automatique à chaque information fournie. 
                </li>
                <li class="mb-2">
                  Vous pouvez revenir à tout moment sur le formulaire. 
                </li>
                <li class="mb-2">
                  10 à 15% des réponses permettent déjà d’avoir un premier niveau de résultat.
                </li>
              </ul>
            </div>
            <span class="cassiopee-grey--text text--darken-3 text-caption">
              Les réponses obligatoires sont indiquées par une *.
            </span>
            <p class="cassiopee-grey--text text--darken-3 text-caption mt-6">
              2 niveaux de rapports finaux (synthétique, détaillé) sont possibles pour un Etablissement ou pour un regroupement d’Etablissements.
            </p>
          </v-card>
        </template>
      </base-modal>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButton from '@/components/BaseButton.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseButtonIcon from '@/components/BaseButtonIcon.vue'

export default {
  name: "TheFormHeader",
  components: {
    BaseButton,
    BaseModal,
    BaseButtonIcon
  },
  methods: {
    ...mapActions('authentication', ['signOut',]),
    logout() {
      this.signOut().then(() => {
        this.$router.push('/login');
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.form-header {
  &__logo {
    padding-left: 71px;
  }
}
a {
  text-decoration: none;
}
</style>
